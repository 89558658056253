import { ClassDataGrid, ClassDataGridRow } from "components/utils/ClassDataGrid/ClassDataGrid";
import { RcPage, RcSection, RcText } from "@financeone/core-ui";
import { CodeBlock } from "components/utils/CodeBlock/CodeBlock";
import { ApplicationStatusesBody } from "manifold/HttpResponseBodies";
import React from "react";

export const Statuses: React.FC = () => {
    const headerRows: ClassDataGridRow[] = [
        {
            name: "x-api-key",
            type: "String",
            required: true,
            description: "The API key provided to you by Finance One",
        },
        {
            name: "Content-Type",
            type: "application/json",
            required: true,
            description: "The accepted MIME type",
        },
    ];
    const paramsRows: ClassDataGridRow[] = [
        {
            name: "brokerId",
            type: "String",
            required: true,
            description: "Broker Id of the broker employee the application was submitted under.",
        },
        {
            name: "page",
            type: "Int",
            required: false,
            description: "Requested page of the resultset. Defaults to 1.",
        },
        {
            name: "limit",
            type: "Int",
            required: false,
            description: "Max number of results per page to return. Defaults to 20.",
        },
    ];

    return (
        <RcPage title="Get Application Statuses">
            <RcSection>
                <RcText>
                    <p>Retrieves a list of applications and their statuses for external display.</p>
                    <p>
                        <code>https://sandbox.api.financeone.com.au/api/v1/applications?brokerId=BE-EXAMPLE</code>
                    </p>
                </RcText>
            </RcSection>
            <RcSection title="Headers">
                <ClassDataGrid propertiesList={headerRows} />
            </RcSection>
            <RcSection title="Parameters">
                <ClassDataGrid propertiesList={paramsRows} />
            </RcSection>
            <RcSection title="Response">
                <CodeBlock language="json" code={ApplicationStatusesBody}></CodeBlock>
            </RcSection>
        </RcPage>
    );
};
